import { browser } from '$app/environment';
import { getAccount } from './auth';

export default async function checkPerm(perm: string) {
	if (browser) {
		const account = await getAccount();
		if (!account) {
			console.log('Cannot check perms - unauthenticated');
			return false;
		}

		const perms = String(account.idTokenClaims.api_roles);
		const permsArray = perms.split(',');

		if (perms.includes(perm)) {
			return true;
		}

		return false;
		// let perms = account.idTokenClaims.

		// const user = keycloak.resourceAccess;
		// if (!user) {
		// 	return false;
		// }
		//
		// if (keycloak.hasResourceRole(perm, import.meta.env.VITE_API_CLIENTID)) {
		// 	return true;
		// }

		return false;
	} else {
		return false;
	}
}
